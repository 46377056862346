import Flatpickr from "stimulus-flatpickr";
import {deleteCookie} from "init/cookie";

export default class extends Flatpickr {
    static targets = ["arrive", "depart", "availability", "availabilityDefaults", "rightArrow", "leftArrow"];

    initialize() {
        this.availabilityData = JSON.parse(this.availabilityTarget.innerHTML);
        this.availabilityDefaults = JSON.parse(
            this.availabilityDefaultsTarget.innerHTML
        );
        if (this.availabilityData === undefined) return;
        this.config = {
            mode: "range",
            minDate: "today",
            showMonths: 2,
            clickOpens: false,
            appendTo: this.element.querySelector('.options_block .calendar .datepicker'),
            positionElement: this.element.querySelector('.options_block .calendar .datepicker'),
            position: 'above',
            defaultDate: this.defaultDates().map(date => {
                // can't use the Flatpickr helper since it isn't instantiated yet...
                // bear in mind that JS months are 0-indexed, whilst days are 1-indexed
                // ¯\_(ツ)_/¯
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const year = date.getFullYear();
                return `${year}-${month}-${day}`;
            }),
            disable: [] // this.getDisabledDates()
        };
        const optionsBlock = this.element.querySelector('.options_block');
        const search = optionsBlock.querySelector('.search');
        if (optionsBlock && search)
            optionsBlock.addEventListener('click', () => {
                const windowInnerHeight = window.innerHeight;
                const elemClientRect = search.getBoundingClientRect();
                if ((elemClientRect.y + elemClientRect.height) >= windowInnerHeight)
                    search.scrollIntoView({behavior: "smooth", block: "end"});
            }, true);
    }

    defaultDates() {
        const arrive = new Date();
        const depart = new Date();
        const offset = parseInt(document.querySelector("#date_offset").value, 10);
        const range = parseInt(document.querySelector("#range_offset").value, 10);
        const arriveDate = arrive.getDate() + offset;
        const departDate = arriveDate + range;
        arrive.setDate(arriveDate);
        depart.setDate(departDate);
        return [arrive, depart];
    }

    connect() {
        this.arriveInput = this.element.querySelector("#arrive");
        this.arriveMoInput = this.element.querySelector(".arrive .small-date");
        this.departInput = this.element.querySelector("#depart");
        this.departMoInput = this.element.querySelector(".depart .small-date");
        this.arriveDateInput = this.element.querySelector("#arrive_date");
        this.departDateInput = this.element.querySelector("#depart_date");
        this.arriveLabel = this.element.querySelector(".arrive-label");
        this.departLabel = this.element.querySelector(".depart-label");
        this.selectDestination = this.element.querySelector(".custom-select > .select-selected")
        this.destinations = document.querySelector("#destinations");
        this.arrowsBlock = this.element.querySelector('.arrows-block');
        this.choosenDatesBlock = this.element.querySelector('.chosen-dates-block');
        this.destinationsDropdown = this.element.querySelector('.custom-select-adv .select');
        this.chosenArrive = this.element.querySelector('.chosen-dates .chosen-arrive');
        this.chosenDepart = this.element.querySelector('.chosen-dates .chosen-depart');

        this.calendar = this.element.querySelector(".calendar");
        this.arriveBlock = this.element.querySelector(".arrive");
        this.departBlock = this.element.querySelector(".depart");
        this.groupDatesBlock = this.element.querySelector(".group .dates");

        this.destinationsDropdown.addEventListener('click', () => {
            if (!this.calendar.classList.contains('hidden')) {
                this.calendar.classList.toggle('hidden');
            }
        })

        this.groupDatesBlock.addEventListener("click", this.togglePicker.bind(this))

        this.calendar.querySelector('.close').addEventListener('click', this.togglePicker.bind(this));

        super.connect();
        // this needs to be called after super.connect() so that this.fp is defined
        this.setDates([]);


        this.rightArrowTarget.addEventListener("click", () =>
            this.fp.changeMonth(1)
        );
        this.leftArrowTarget.addEventListener("click", () =>
            this.fp.changeMonth(-1)
        );

        document.addEventListener('click', (e) => {
            if (!this.element.contains(e.target) && !this.arrowsBlock.classList.contains('hidden')) {
                this.arrowsBlock.classList.add('hidden');
            }
            if (!this.element.contains(e.target) && !this.choosenDatesBlock.classList.contains('hidden')) {
                this.choosenDatesBlock.classList.add('hidden');
            }
        });
        // remove `animate` class from calendar since it was causing it to jump
        // when applying `transform` css to adjust the position to the design
        document.querySelector(".flatpickr-calendar").classList.remove("animate");
        this.clearCalendarClass();
        // Add the "Clear Dates" link to the flatpicker calendar.
        this.addClearDatesButton();
        this.handlerViewport();
    }

    handlerViewport() {
        const select = this.element.querySelector('.custom-select-adv .select');
        const destinationOptions = select.querySelector('.select-options');
        select.classList.add('select--up');
        destinationOptions.classList.add('up');

        window.addEventListener('scroll', () => {
            if (window.scrollY <= 200) {
                select.classList.add('select--up');
                destinationOptions.classList.add('up');
            } else {
                select.classList.remove('select--up');
                destinationOptions.classList.remove('up');
            }
        })
    }

    addClearDatesButton() {
        const calendarEl = document.querySelector(".flatpickr-calendar");
        // If the flatpickr is on the page, and doesn't have a clear dates button.
        if (
            this.fp.element &&
            calendarEl &&
            !calendarEl.querySelector(".clear-dates")
        ) {
            const clearDatesButton = document.createElement("button");
            clearDatesButton.classList.add("clear-dates");
            clearDatesButton.textContent = "Clear All";
            calendarEl.appendChild(clearDatesButton);
            clearDatesButton.addEventListener("click", (e) => {
                e.preventDefault();
                deleteCookie("filter_by_dates");
                this.fp.close();
                document.body.style.overflowY = "auto";
                this.clearCalendarClass();
                calendarEl
                    .querySelectorAll(".flatpickr-day")
                    .forEach(el =>
                        el.classList.remove("selected", "startRange", "inRange", "endRange")
                    );
                this.setDates([]);
                this.arriveLabel.classList.remove('hidden');
                this.departLabel.classList.remove('hidden');
                this.arriveInput.textContent = "";
                this.departInput.textContent = "";
                // this.arriveLabel.classList.remove('hidden');
                // this.departLabel.classList.remove('hidden');
                // this.arriveMoInput.parentNode.classList.add('hidden');
                // this.departMoInput.parentNode.classList.add('hidden');
                this.arrowsBlock.classList.add('hidden');
                this.choosenDatesBlock.classList.add('hidden');
                this.groupDatesBlock.classList.remove('dates--selected');
            });
        }
    }

    togglePicker(e) {
        e.preventDefault();

        if (this.destinationsDropdown.classList.contains("select--open")) {
            this.destinationsDropdown.classList.toggle("select--open");
        }

        this.calendar.classList.toggle('hidden');
        const pickerType = e.currentTarget.parentNode.classList;

        if (this.calendar.classList.contains('hidden')) {
            this.clearCalendarClass();
            this.fp.open(e, e.target);
        } else {
            this.updateCalendarClass('');
        }
    }

    OLDtogglePicker(e) {
        // Grab the class lists we're comparing to determine if the target is open.
        const calendarType = document.querySelector(".flatpickr-calendar")
            .classList;
        const pickerType = e.currentTarget.parentNode.classList;
        // Close the flatpickr.
        this.fp.close();
        document.body.style.overflowY = "auto";

        // Compare the calendar class to the target.
        if (calendarType.contains(pickerType)) {
            // If calendar and target are the same, it was open and we can clear it.
            this.clearCalendarClass();
            this.arrowsBlock.classList.add('hidden');
            this.choosenDatesBlock.classList.add('hidden');
        } else {
            // Otherwise, we should add the target class to the calendar and open it.
            this.updateCalendarClass(pickerType);
            this.fp.open(e, e.target);
            this.arrowsBlock.classList.remove('hidden')
            this.choosenDatesBlock.classList.remove('hidden');
        }
    }

    setDates(dates) {
        const lookup = ["arrive", "depart"];
        // const labels = { "arrive": "Check in", "depart": "Check out" }
        dates.forEach((date, index) => {
            const dayInput = this[`${lookup[index]}Input`];
            // const monthInput = this[`${lookup[index]}MoInput`];
            const dateInput = this[`${lookup[index]}DateInput`];
            // dayInput.innerText = this.fp.formatDate(date, "j");
            dayInput.innerText = this.fp.formatDate(date, 'M j, Y');
            // monthInput.innerText = this.fp.formatDate(date, "M Y");
            // monthInput.innerText = labels[lookup[index]];
            dateInput.value = this.fp.formatDate(date, "Y-m-d");
        });
        if (dates && dates.length > 1) {
            this.fp.setDate(dates);
        }
    }

    clearCalendarClass() {
        const calendar = document.querySelector(".flatpickr-calendar");
        calendar.classList.remove("arrive");
        calendar.classList.remove("depart");
    }

    updateCalendarClass(type) {
        this.clearCalendarClass();
        const calendar = document.querySelector(".flatpickr-calendar");
        if (type) calendar.classList.add(type);
    }

    change(selectedDates, dateStr, instance) {
        if (selectedDates[0] === undefined) return
        const lookup = ["arrive", "depart"];
        const index = selectedDates.length % 2;
        const type = lookup[index];
        const clickedType = lookup[Math.abs(index - 1)]
        let dayInput = this[`${type}Input`];
        const startDate = instance.formatDate(selectedDates[0], 'F j, Y');
        const startEl = document.querySelector(`[aria-label="${startDate}"]`);
        let disable;

        if (clickedType === lookup[0]) {
            this.chosenArrive.textContent = instance.formatDate(selectedDates[0], 'M j, Y');
            this.arriveLabel.classList.add('hidden');
            if (selectedDates.length === 1) {
                this.chosenDepart.textContent = 'Check out'
            }
        } else if (clickedType === lookup[1]) {
            this.chosenDepart.textContent = instance.formatDate(selectedDates[1], 'M j, Y');
        }

        // do not allow departure days to be selected as arrival date
        if (selectedDates.length >= 1 &&
            (startEl !== null && startEl.classList.value.match(/flatpickr-departure|flatpickr-sold-out/))) {
            disable = true;
        }

        // do not allow departure days to be within date selection
        if (!disable && selectedDates.length === 2) {
            const range = this.getDaysArray(selectedDates[0], selectedDates[1])
            range.shift()
            range.pop()
            disable = range.find(d => {
                const date = instance.formatDate(d, 'F j, Y')
                const el = document.querySelector(`[aria-label="${date}"]`)
                return (el !== null &&
                    el.classList.value.match(/flatpickr-departure|flatpickr-sold-out/))
            })
        }

        if (disable) {
            this.clearInstanceDates(instance)
            this.fp.open();
            dayInput = this.departInput
        } else {
            // this.updateCalendarClass(type);
            this.clearCalendarClass();
            this.setDates(selectedDates);
            // this.element.querySelector(`.${clickedType}.hidden`).classList.remove('hidden');
            // this.element.querySelector(`.${clickedType} > .${clickedType}-label`).parentNode.classList.add('hidden');

            if (selectedDates.length === 2) {
                this.groupDatesBlock.classList.add('dates--selected');
                this.calendar.classList.add('hidden');
                this.arriveLabel.classList.add('hidden');
                this.departLabel.classList.add('hidden');

                if (!this.destinationsDropdown.classList.contains('select--selected')) {
                    this.destinationsDropdown.classList.add('select--open');
                }
            }
        }
        /* eslint-disable-next-line no-underscore-dangle */
        instance._positionCalendar(dayInput);
    }

    getDaysArray(start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt))
        }
        return arr;
    }

    clearInstanceDates(instance) {
        const firstVisibleDay = document.querySelector('.flatpickr-day:not(.hidden):not(.prevMonthDay)')
        const date = new Date(firstVisibleDay.getAttribute('aria-label'))
        instance.clear()
        instance.jumpToDate(date)
    }


    // we probably dont need it anymore since the home page calendar
    // supposed to always be available.
    // dayCreate(dObj, dStr, fp, dayElem) {
    //     const humanDate = dayElem.getAttribute("aria-label");
    //     const formatted = fp.formatDate(new Date(humanDate), "Y-m-d");
    //     const day = this.availabilityData[formatted];
    //     if (typeof day === "undefined") return;
    //     if (day.depart === 1) {
    //         // since departure days are technically disabled this is a hack to allow us to select them
    //         dayElem.classList.add('flatpickr-departure');
    //         dayElem.classList.remove('flatpickr-disabled');
    //         let selected = fp.selectedDates[1]
    //         if (selected) {
    //             selected = fp.formatDate(new Date(selected), 'Y-m-d')
    //             if (selected === formatted) {
    //                 dayElem.classList.add('selected')
    //                 dayElem.classList.add('endRange')
    //             }
    //         }
    //     } else if (day.avail === 0) {
    //         dayElem.classList.add('flatpickr-sold-out')
    //     }
    // }

    getDisabledDates() {
        const availability = this.availabilityData;
        if (availability === undefined) return;
        // let date = document.getElementById("depart_date").value;
        const disabled = Object.keys(availability).filter(
            key => availability[key].avail === 0
        );
        // eslint-disable-next-line consistent-return
        return disabled;
    }

    submit(e) {
        e.preventDefault();
        const arrive = this.arriveTarget.value;
        const depart = this.departTarget.value;
        const destinationTarget = document.querySelector("#destinations");

        const destinations = [];
        if (destinationTarget) {
            for (let i = 0; i < destinationTarget.options.length; i++) {
                if (destinationTarget.options[i].selected) {
                    destinations.push(destinationTarget.options[i].value);
                }
            }
        }

        const destination = destinations.join(',');
        const numberOfGuests = document.querySelector('#number_guests');

        // if (arrive === "" || depart === "") return;
        let queryData = {};

        if (destination !== '') {
            queryData = Object.assign(queryData, {destinations: destination});
        }

        if(numberOfGuests.value != '0'){
          queryData = Object.assign(queryData, {guests: numberOfGuests.value});
        }

        if (arrive) {
            queryData = Object.assign(queryData, {checkin: arrive});
        }

        if (depart) {
            queryData = Object.assign(queryData, {checkout: depart});
        }

        /* eslint-disable-next-line no-undef */
        const query = Site.objectToQuery(queryData);

        window.location = `/stay/?${query}`;
    }
}
